<template>
  <b-container id="footer-container" fluid :style="'color: ' + textColor">
    <b-row class="my-3">
      <b-col>
          <p style="text-align: right;">
            &copy; NotesCoach
          </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
  props: {
    textColor: {
      type: String,
      default: 'black',
    },
  },
  components: {},
};
</script>
