import axios from '@axios';
import Vue from 'vue';

export default function intercept() {
    axios.interceptors.request.use(
        (request) => {
            request.config = {
                showToast: false,
                ...(request.config || {}),
            }

            return request;
        }
    );

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const response = error.response;
            console.log(response);
            if (response.data.errors) {
                if (Array.isArray(response.data.errors)) {
                    response.data.errors.forEach(error => {
                        Vue.$toast.open({
                            message: error || 'Une erreur est survenue',
                            type: 'error',
                        })
                    })
                } else {
                    for (const [, value] of Object.entries(response.data.errors)) {
                        Vue.$toast.open({
                            message: value || 'Une erreur est survenue',
                            type: 'error',
                        })
                    }
                }
            } else {
                Vue.$toast.open({
                    message: response.data.message || 'Une erreur est survenue',
                    type: 'error',
                })
            }
            return Promise.reject(error);
        }
    );
}