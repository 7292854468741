<template>
  <div id="app" :style="appStyle">
    <Navbar :textColor="textColor" />

    <b-container fluid class="py-5 main-container">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view 
          @changeBackgroundColor="changeBackgroundColor"
          @changeTextColor="changeTextColor"
        />
      </transition>
    </b-container>

    <Footer :textColor="textColor" />
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      backgroundColor: '#ffffff',
      textColor: 'black'
    };
  },
  computed: {
    screenHeight() {
      return this.$vssHeight
    },
    appStyle() {
      return this.$route.name == 'login' ? 
        `height: ${this.screenHeight}px; background-color: ${this.backgroundColor};` :
        `height: 100%; background-color: ${this.backgroundColor};`
    },
  },
  methods: {
    changeBackgroundColor(color) {
      this.backgroundColor = color
    },
    changeTextColor(color) {
      this.textColor = color
    },
  },
}
</script>
