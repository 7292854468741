import store from '@/store'
import router from '@/router';
import axios from '@axios'
// import useJwt from '@/libs/auth/jwt/useJwt'
import jwt from '@/auth/jwt/useJwt'

const login = async (email, password) => {
    try {
      const res = await axios.post('/auth/login', { email: email, password: password })
      // const jwt = useJwt(axios).jwt

      store.commit('setUser', res.data.user)

      jwt.setToken(res.data.tokens.access.token)
      jwt.setRefreshToken(res.data.tokens.refresh.token)

      router.push({ name: 'projects-list' })
    } catch(err) {
      console.error(err)
    }
}

const logout = () => {
  jwt.deleteToken()
  jwt.deleteRefreshToken()
  jwt.deleteUserData()
}

const fetchProjects = async () => {
    try {
      const res = await axios.get('/projects?limit=100')

      return res.data.results
    } catch(err) {
      console.error(err)
    }
}

const fetchProject = async (projectId, workshopSharedLinkHash = '', workshopId = '') => {
    try {
      const res = await axios.get(`/projects/${projectId}?sharedLinkHash=${workshopSharedLinkHash}&workshopId=${workshopId}`)

      return res.data
    } catch(err) {
      console.error(err)
    }
}

const createProject = async (project) => {
    try {
      await axios.post('/projects', project)
    } catch(err) {
      console.error(err)
    }
}

const deleteProject = async (projectId) => {
    try {
      await axios.delete(`/projects/${projectId}`)
    } catch(err) {
      console.error(err)
    }
}

const fetchWorkshop = async (workshopId, workshopSharedLinkHash = '') => {
    try {
      const res = await axios.get(`/workshops/${workshopId}?sharedLinkHash=${workshopSharedLinkHash}&workshopId=${workshopId}`)
      
      return res.data
    } catch(err) {
      console.error(err)
    }
}

const createWorkshop = async (workshop) => {
    try {
      await axios.post('/workshops', workshop)
    } catch(err) {
      console.error(err)
    }
}

const updateWorkshop = async (workshopId, payload) => {
    try {
      await axios.patch(`/workshops/${workshopId}`, payload)
    } catch(err) {
      console.error(err)
    }
}

const deleteWorkshop = async (workshopId) => {
  try {
    await axios.delete(`/workshops/${workshopId}`)
  } catch(err) {
    console.error(err)
  }
}

const uploadFile = async (file, workshopId) => {
  try {
    await axios.post(`/workshops/uploadFile/${workshopId}`, file, { 
      headers: { 'Content-Type': 'multipart/form-data' }
    })  
  } catch(err) {
    console.error(err)
  }
}

const sendWorkshopInviteByEmail = async (workshopId, email) => {
    try {
      await axios.post(`/workshops/${workshopId}/email`, { email })
    } catch(err) {
      console.error(err)
    }
}
  
export {
    login,
    logout,
    fetchProjects,
    fetchProject,
    createProject,
    deleteProject,
    fetchWorkshop,
    createWorkshop,
    updateWorkshop,
    deleteWorkshop,
    uploadFile,
    sendWorkshopInviteByEmail,
}