import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils';
import { fetchWorkshop } from '@/helpers/apiService'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      alias: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Login.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Connection',
        authRequired: false,
      },
    },
    {
      path: '/projects',
      name: 'projects-list',
      component: () => import(/* webpackChunkName: "manager" */'@/views/ProjectsList.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Mes projets',
        authRequired: true,
      },
    },
    {
      path: '/projects/:projectId',
      name: 'project',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Project.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Mon projet',
        authRequired: true,
      },
    },
    {
      path: '/workshop/:workshopId',
      name: 'workshop',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Workshop.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Mon atelier',
        authRequired: true,
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */'@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        pageTitle: '404 Error',
        authRequired: false,
      },
    },
    {
      path: '*',
      redirect: '404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const authRequired = to.matched.some(record => record.meta.authRequired)

  if (to.name == 'workshop' && to.query.share) {
    const workshopSharedLinkHash = to.query.share ? to.query.share : ''
    const res = await fetchWorkshop(to.params.workshopId, workshopSharedLinkHash)

    if (res.hash == workshopSharedLinkHash) {
      return next()
    }
  }

  // Redirect to login if not logged in
  if (authRequired && !isLoggedIn) {
    return next({ name: 'login' })
  }
 
  if (to.name == 'login' && isLoggedIn) {
    return next({ name: 'projects-list' })
  }

  document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.pageTitle}`

  return next()
})

export default router