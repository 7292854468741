<template>
  <b-navbar v-if="$route.name != 'login'" class="navbar">
    <b-navbar-brand href="/" :style="'color: ' + textColor">
      <img class="logo" src="/assets/logo-blue.png">
    </b-navbar-brand>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-button @click.prevent="logoutAndRedirect" right>Déconnexion</b-button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { logout } from '@/helpers/apiService'

export default {
  name: "Navbar",
  props: {
    textColor: {
      type: String,
      default: 'black',
    },
  },
  components: {},
  methods: {
    logoutAndRedirect() {
      logout();
      this.$router.push({ name: 'login' });
    },
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 120px;
}
</style>