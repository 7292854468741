import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
 
Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
})
 
const store = new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setUser (state, payload) {
        state.user = payload
    },
  },
  plugins: [vuexLocalStorage.plugin]
})
 
export default store